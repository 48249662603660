var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recognition-page-Two" },
    [
      _c("RecognitionPage", {
        attrs: { backgroundImage: _vm.bgImg_ni, wordObj: _vm.wordDetail_ni },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }