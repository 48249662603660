var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "singpage-container",
      class: { "layout-border": !_vm.isReviewPage },
    },
    [
      _c("SingPage", {
        attrs: {
          singList: _vm.singList,
          songInfo: _vm.songInfo,
          timeStampList: _vm.timeStampList,
          isReviewPage: _vm.isReviewPage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }