<template>
  <div class="game-container">
    <FindElementGame
      :bgImg="bgImg"
      :imgList="imgList"
      :lessonNo="59"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import FindElementGame from "@/components/Course/GamePage/FindElementGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-59-1/background.svg"),
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-59-1/number-45.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-59-1/number-30.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-59-1/number-12.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-59-1/number-7.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-59-1/number-3.svg"),
        },
      ],
      titleInfo: {
        // pinyin:"Nǐ hǎo! Nǐ chī shénme?",
        hanzi: "3、7、12、30、45",
      },
    };
  },
  components: {
    FindElementGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
