<template>
  <div class="game-container">
    <MirrorGame
      :mirrorImgList="mirrorImgList"
      :optionList="optionList"
      :bgImg="bgImg"
    />
  </div>
</template>

<script>
import MirrorGame from "@/components/Course/GamePage/MirrorGame";
export default {
  data() {
    return {
      bgImg:require("@/assets/img/16-GAMES/G03-mirror/lesson-59/background-bathroom.svg"),
      optionList: [
        {
          pinyin: "diǎn",
          hanzi: "点",
          id: 0,
        },
        {
          pinyin: "fēn",
          hanzi: "分",
          id: 1,
        },
      ],
      mirrorImgList: [
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-59/image-1-fen.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-59/image-2-fen.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-59/image-3-dian.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-59/image-4-fen.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-59/image-5-dian.svg"),
          id: 0,
        },
        
      ],
    };
  },
  components: {
    MirrorGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











