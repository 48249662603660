<template>
  <div class="lesson-container">
    <DialogPage
      :bgImg="bgImg"
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :titleInfo="titleInfo"
      :lessonNo="592"
      :isAllScreen="true"
    />
  </div>
</template>

<script>
import DialogPage from "@/components/Course/CoursePage/DialogPage";
export default {
  data() {
    return {
      clickIdList:[1,2,3,4,5],
      bgImg: require("@/assets/img/03-Backgrounds/level-1-background-blue.svg"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/menu-3.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/menu-4.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/image-1.svg"),
          singlePageList: [
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-1.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/image-2.svg"),
          singlePageList: [
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-2.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/image-3.svg"),
          singlePageList: [
             {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-3.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 4,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/image-4.svg"),
          singlePageList: [
             {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-4.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-59-2/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
      ],
      titleInfo:{
        pinyin:"Xiànzài jǐ diǎn?",
        hanzi:"现在几点？"
      }
    };
  },
  components: {
    DialogPage,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>