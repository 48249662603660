<template>
  <div class="page-container">
    <DrawingPage :bgImg="bgImg" :lessonNo="59"/>
  </div>
</template>

<script>
import DrawingPage from "@/components/Course/CoursePage/DrawingPage";
export default {
  data() {
    return {
      bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-59-2/background-tamagochi.svg`),
    };
  },
  components: {
    DrawingPage,
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  height: 100%;
}
</style>
