<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "xiànzài",
          hanzi: "现在",
          english: "now",
          image: require(`@/assets/img/12-Vocabulary/lesson-59-xianzai-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "diǎn",
          hanzi: "点",
          english: "o'clock",
          image: require(`@/assets/img/12-Vocabulary/lesson-59-dian-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "fēn",
          hanzi: "分",
          english: "minute",
          image: require(`@/assets/img/12-Vocabulary/lesson-59-fen-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "jiàn(miàn)",
          hanzi: "见（面）",
          english: "to meet",
          image: require(`@/assets/img/12-Vocabulary/lesson-59-jianmian-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "Xiànzài jǐ diǎn?",
          hanzi: "现在几点？",
          english: "What time is it?",
          image: require(`@/assets/img/12-Vocabulary/lesson-59-xianzai-ji-dian.svg`),
          blue: true,
          longtext:true
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
