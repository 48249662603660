<template>
  <div class="game-container">
    <FindElementByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :lessonId="lessonId"
    />
  </div>
</template>

<script>
import FindElementByPicGame from "@/components/Course/GamePage/FindElementByPicGame";
export default {
  data() {
    return {
      totalStars: 6,
      lessonId:59,
      questionInfoList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-59/image-1-shidian.svg"),
          sound: require("@/assets/audio/a-zh/n/ni3.mp3"),
        },

        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-59/image-2-badian.svg"),
          sound: require("@/assets/audio/a-zh/w/wo3.mp3"),
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-59/image-3-yidian.svg"),
          sound: require("@/assets/audio/a-zh/n/nihao.mp3"),
        },
        {
          id: 4,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-59/image-4-shierdian.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-59/image-5-wudian.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        {
          id: 6,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-59/image-6-shiyidian.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 2,
            element: "八点",
            pinyin: "bādiǎn",
          },
          {
            id: 5,
            element: "五点",
            pinyin: "wúdiǎn",
          },
          {
            id: 1,
            element: "十点",
            pinyin: "shídiǎn",
          },
        ],
        rightList: [
          {
            id: 6,
            element: "十一点",
            pinyin: "shíyīdiǎn",
          },
          {
            id: 4,
            element: "十二点",
            pinyin: "shíerdiǎn",
          },
          {
            id: 3,
            element: "一点",
            pinyin: "yīdiǎn",
          },
        ],
      },
    };
  },
  components: {
    FindElementByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
