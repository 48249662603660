<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-59.mp3"),
      },
      timeStampList: [3.3, 7.3, 10.6, 14.3, 22, 25.4, 31, 34],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Xiànzài bā diǎn! bā diǎn! bā diǎn!",
            chineseWords: "现在8点！8点！8点！",
          },
          {
            id: 2,
            spelling: "Wǒ bā diǎn qù xuéxiào.",
            chineseWords: "我8点去学校。",
          },
          {
            id: 3,
            spelling: "Xiànzài shí'èr diǎn! shí'èr diǎn! shí'èr diǎn!",
            chineseWords: "现在12点！12点！12点！",
          },
          {
            id: 4,
            spelling: "Wǒ shí'èr diǎn chī fàn.",
            chineseWords: "我12点吃饭。",
          },
          {
            id: 5,
            spelling: "Lǎoshī! Xiànzài jǐ diǎn?",
            chineseWords: "老师！现在几点？",
          },
          {
            id: 6,
            spelling: "Xiànzài wǔ diǎn sānshí fēn.",
            chineseWords: "现在5点30分。",
          },
          {
            id: 7,
            spelling: "Lǎoshī, zàijiàn!",
            chineseWords: "老师，再见！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
